import { Box } from '@mui/material'
import { ReferenceInput, Create, DateTimeInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin'
import { dateTimeZoneFormatters } from '@/services/api/helpers'
import { EStadium } from '@/modules/Stadiums/types'
import { EConcert } from '@/modules/Concerts/types'

export const ConcertsCreate = () => {
  return (
    <Create title="Create new Concert" redirect="list">
      <SimpleForm>
        <Box
          display={{
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ArTitle} label="Ar Title" fullWidth validate={required()} />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ArSubtitle} label="Ar Subtitle" fullWidth />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ArAddress} label="Ar Address" fullWidth validate={required()} />
            </Box>
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.KuTitle} label="Ku Title" fullWidth />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.KuSubtitle} label="Ku Subtitle" fullWidth />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.KuAddress} label="Ku Address" fullWidth />
            </Box>
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ImageLink} label="Image Link" fullWidth validate={required()} />
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <DateTimeInput
                source={EConcert.DateAndTime}
                fullWidth
                required
                {...dateTimeZoneFormatters}
                validate={required()}
              />
            </Box>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source={EConcert.StadiumId} reference="stadiums" perPage={1000} fullWidth>
              <SelectInput optionText={EStadium.ArName} label="Stadium" validate={required()} fullWidth />
            </ReferenceInput>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}
