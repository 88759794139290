import { NO_WHITESPACE } from '@/consts/regexp'
import { Box } from '@mui/material'
import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  regex,
  required,
} from 'react-admin'
import { EBrand } from './types'

export const BrandsCreate = () => (
  <Create title="Create new Brand" redirect="list">
    <SimpleForm sanitizeEmptyValues>
      <Box
        display={{
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <TextInput
          source={EBrand.EnName}
          label="English Name"
          validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />
        <TextInput
          source={EBrand.ArName}
          label="Arabic Name"
          validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />

        <TextInput
          source={EBrand.EnDescription}
          label="English Description"
          validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />
        <TextInput
          source={EBrand.ArDescription}
          label="Arabic Description"
          validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />

        <TextInput
          source={EBrand.Logo}
          label="Logo Url"
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          required
          fullWidth
        />
        <BooleanInput source={EBrand.ShowInHome} fullWidth />
        <NumberInput
          source={EBrand.Order}
          label="Order"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Order must be an integer')}
          fullWidth
        />
        <BooleanInput source={EBrand.IsActive} fullWidth />
        <BooleanInput source={EBrand.IsGlobal} fullWidth />
        <ReferenceInput source={EBrand.SubCategoryId} reference="sub-categories" perPage={1000}>
          <SelectInput optionText={EBrand.EnName} validate={required()} fullWidth />
        </ReferenceInput>
      </Box>
    </SimpleForm>
  </Create>
)
