import { Box } from '@mui/material'
import {
  ReferenceInput,
  ArrayInput,
  WithRecord,
  DateTimeInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  minValue,
  required,
} from 'react-admin'
import { dateTimeZoneFormatters } from '@/services/api/helpers'
import { EStadium } from '@/modules/Stadiums/types'
import { EConcert, EEventState } from '@/modules/Concerts/types'

export const ConcertsEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        <Box
          display={{
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ArTitle} label="Ar Title" fullWidth validate={required()} />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ArSubtitle} label="Ar Subtitle" fullWidth />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ArAddress} label="Ar Address" fullWidth validate={required()} />
            </Box>
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.KuTitle} label="Ku Title" fullWidth />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.KuSubtitle} label="Ku Subtitle" fullWidth />
            </Box>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.KuAddress} label="Ku Address" fullWidth />
            </Box>
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={EConcert.ImageLink} label="Image Link" fullWidth validate={required()} />
            </Box>

            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <DateTimeInput
                source={EConcert.DateAndTime}
                fullWidth
                required
                {...dateTimeZoneFormatters}
                validate={required()}
              />
            </Box>
            <Box mr={{ xs: 0, sm: '0.5em' }}>
              <DateTimeInput source={EConcert.PublishedAt} fullWidth {...dateTimeZoneFormatters} />
            </Box>
          </Box>
          <Box mr={{ xs: 0, sm: '0.5em' }}>
            <WithRecord
              render={(record) => (
                <ReferenceInput source={EConcert.StadiumId} reference="stadiums" required perPage={1000}>
                  <SelectInput
                    label="Stadium"
                    optionText={EStadium.ArName}
                    defaultValue={record[EConcert.Stadium][EStadium.Id]}
                    validate={required()}
                    fullWidth
                  />
                </ReferenceInput>
              )}
            />
          </Box>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput
              source={EConcert.State}
              label="State"
              choices={[
                { id: EEventState.ON_SALE, name: 'On Sale' },
                { id: EEventState.SOLD_OUT, name: 'Sold Out' },
              ]}
              validate={required()}
              fullWidth
            />
          </Box>
          <Box mr={{ xs: 0, sm: '0.5em' }}>
            <ArrayInput source={EConcert.Categories}>
              <SimpleFormIterator disableClear disableRemove disableAdd disableReordering fullWidth>
                <TextField source="label" />
                <NumberInput source="price" validate={minValue(1, 'Price must be equal or higher than 1')} />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  )
}
