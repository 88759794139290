import { EConcert } from '@/modules/Concerts/types'
import { ESortOrder } from '@/types/data'
import {
  WrapperField,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  SearchInput,
  DateTimeInput,
  ImageField,
  WithRecord,
} from 'react-admin'
import { Box } from '@mui/material'
import { EStadium } from '@/modules/Stadiums/types'

interface NotPublishedStyleProps {
  backgroundColor: string
  color: string
  borderRadius: string
  padding: string
  width: string
  display: string
  justifyContent: string
}

const notPublishedStyle: NotPublishedStyleProps = {
  backgroundColor: '#ad2f44',
  color: 'white',
  borderRadius: '30px',
  padding: '5px',
  width: '6rem',
  display: 'flex',
  justifyContent: 'center',
}
const notPublishedStyleCSS: React.CSSProperties = notPublishedStyle

const concertsList = [
  <SearchInput source={EConcert.ArTitleLike} alwaysOn key={1} placeholder="Search (Arabic title)" />,
  <SearchInput source={EConcert.ArSubtitleLike} alwaysOn key={2} placeholder="Search (Arabic subtitle)" />,
  <DateTimeInput source={EConcert.MinDateAndTime} key={6} label="Search (Min date)" />,
  <DateTimeInput source={EConcert.MaxDateAndTime} key={7} label="Search (Max date)" />,
]
export const ConcertsList = () => {
  return (
    <List filters={concertsList} sort={{ field: EConcert.CreatedAt, order: ESortOrder.Desc }}>
      <Datagrid rowClick="edit">
        <NumberField source={EConcert.Id} textAlign="left" sortable />
        <TextField source={EConcert.EventKey} sortable={false} />
        <DateField source={EConcert.DateAndTime} showTime label={<span>Date and Time</span>} sortable={false} />
        <WithRecord
          label="Published At"
          render={(record) => {
            switch (record[EConcert.PublishedAt]) {
              case null: {
                return <div style={notPublishedStyleCSS}>Not Published</div>
              }
              default: {
                return <DateField source={EConcert.PublishedAt} showTime label={<span>Published At</span>} sortable />
              }
            }
          }}
        />
        <TextField source={EConcert.ArTitle} label="Title (ar)" sortable />
        <TextField source={EConcert.ArSubtitle} label="Subtitle (ar)" sortable />
        <TextField source={EConcert.ArAddress} label="Address (ar)" />
        <ImageField
          label="Image"
          source={`${EConcert.ImageLink}`}
          sx={{
            '& img': {
              maxWidth: '100px',
              maxHeight: 'auto',
              objectFit: 'cover !important',
              borderRadius: '10%',
            },
            display: 'flex',
            marginRight: '8px',
          }}
        />
        <WrapperField label="Stadium">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            padding="10 20"
            justifyContent="space-between"
            width="200px"
            border="1px solid blue"
          >
            <Box marginBottom="12px">
              <p>
                <b>Arab Name</b>
              </p>
              <TextField source={`${EConcert.Stadium}.${EStadium.ArName}`} sortable={false} />
            </Box>
            <Box marginBottom="12px">
              <p>
                <b>Ku Name</b>
              </p>
              <TextField source={`${EConcert.Stadium}.${EStadium.KuName}`} sortable={false} />
            </Box>
          </Box>
        </WrapperField>
      </Datagrid>
    </List>
  )
}
