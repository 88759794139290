import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ChipField,
  WrapperField,
  DateField,
  BulkExportButton,
  BulkDeleteButton,
  NumberInput,
  SelectInput,
  SearchInput,
  required,
  BooleanField,
  useRefresh,
  NumberField,
} from 'react-admin'
import { Box } from '@mui/material'

import { tokens } from '@/Layout/theme'
import { EProduct, EType } from '@/modules/Products/types'
import { typeChoices } from '@/modules/Products/consts'
import { LabelField } from '@/components/LabelField'
import { EBrand } from '@/modules/Brands/types'

const productsFilters = [
  <SearchInput source={EProduct.BrandEnNameLike} alwaysOn key={1} placeholder="Search (Brand En name)" />,
  <SelectInput
    label="Type Filter"
    source={EProduct.Type}
    defaultValue={EType.BUNDLE}
    key={EProduct.Type}
    choices={typeChoices}
    validate={required()}
    optionValue="name"
  />,
  <NumberInput source={EProduct.BrandId} key={1} label="Brand Id" />,
]

export const ProductsList = () => {
  const colors = tokens()
  const refresh = useRefresh()

  return (
    <List filters={productsFilters}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          <>
            <BulkExportButton />
            <BulkDeleteButton mutationOptions={{ onMutate: refresh }} />
          </>
        }
      >
        <TextField source={EProduct.Id} />

        <WrapperField label="Product - English Name" sortBy={EProduct.EnName}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImageField
              source={EProduct.Logo}
              sx={{
                '& img': {
                  maxWidth: 50,
                  maxHeight: 50,
                  objectFit: 'cover !important',
                  borderRadius: '50%',
                },
                display: 'flex',
                marginRight: '8px',
              }}
              sortable={false}
            />
            <TextField source={EProduct.EnName} />
          </div>
        </WrapperField>
        <TextField source={EProduct.ArName} label="Product - Arabic Name" />

        <ChipField
          sx={{
            backgroundColor: colors.accent.pink,
            color: colors.neutral[0],
          }}
          source={EProduct.Type}
          label="Type"
          sortable={false}
        />
        <LabelField source={EProduct.Provider} sortable={false} />
        <WrapperField label="Brand">
          <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
            <TextField source={`${EProduct.Brand}.${EBrand.EnName}`} label="English Name" sortable={false} />
            <TextField source={`${EProduct.Brand}.${EBrand.ArName}`} label="Arabic Name" sortable={false} />
          </Box>
        </WrapperField>

        <NumberField source={`${EProduct.Brand}.${EBrand.Id}`} textAlign="left" label="Brand Id" sortable={false} />

        <WrapperField label="Redeem Instructions">
          <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
            <TextField source={EProduct.EnRedeemInstructions} sortable={false} />
            <TextField source={EProduct.ArRedeemInstructions} sortable={false} />
          </Box>
        </WrapperField>

        <BooleanField source={EProduct.IsActive} sortable={false} />
        <NumberField source={EProduct.Order} sortable />
        <DateField source={EProduct.CreatedAt} sortable={false} />
        <DateField source={EProduct.UpdatedAt} sortable={false} />
      </Datagrid>
    </List>
  )
}
