import { NO_WHITESPACE } from '@/consts/regexp'
import { minLengthTrimmed, requiredTrimmed } from '@/utils/validation'
import { Box } from '@mui/material'
import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  regex,
  required,
} from 'react-admin'
import { EBrand } from '../Brands/types'
import { providerChoices, typeChoices } from './consts'
import { EProduct } from './types'

export const ProductsCreate = () => (
  <Create title="Create new Product" redirect="list">
    <SimpleForm sanitizeEmptyValues>
      <Box
        display={{
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <TextInput
          required
          source={EProduct.EnName}
          label="English Name"
          validate={[requiredTrimmed, minLengthTrimmed(1)]}
          fullWidth
        />
        <TextInput
          required
          source={EProduct.ArName}
          label="Arabic Name"
          validate={[requiredTrimmed, minLengthTrimmed(1)]}
          fullWidth
        />

        <SelectInput
          required
          source={EProduct.Type}
          choices={typeChoices}
          validate={requiredTrimmed}
          optionValue="name"
          fullWidth
        />
        <SelectInput
          required
          source={EProduct.Provider}
          choices={providerChoices}
          validate={requiredTrimmed}
          optionValue="name"
          fullWidth
        />

        <TextInput
          required
          source={EProduct.Logo}
          label="Logo Url"
          validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          fullWidth
        />

        <ReferenceInput source={EProduct.BrandId} reference="brands" perPage={1000}>
          <SelectInput optionText={EBrand.EnName} validate={required()} fullWidth />
        </ReferenceInput>

        <TextInput source={EProduct.EnRedeemInstructions} label="English Redeem Instructions" fullWidth />
        <TextInput source={EProduct.ArRedeemInstructions} label="Arabic Redeem Instructions" fullWidth />
        <BooleanInput source={EProduct.IsActive} fullWidth />
        <NumberInput
          source={EProduct.Order}
          label="Order"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Order must be an integer')}
          fullWidth
        />
      </Box>
    </SimpleForm>
  </Create>
)
